import { createAsyncThunk } from '@reduxjs/toolkit'
import { logger, MimeMatcher, shortUuid, type StoredObject } from '@tunasong/models'
import { isAudio, type Entity, type EntityType, type Persisted } from '@tunasong/schemas'
import invariant from 'tiny-invariant'
import { entitiesApi } from '../../api/entities.js'
import { mediaApi } from '../../api/media.js'
import { storageApi } from '../../api/storage.js'
import { type RootState } from '../../configure-store.js'
import { type TunaThunkDispatch } from '../../hooks/thunk-dispatch.hook.js'
import { createObjectPath } from '../../util.js'
import { notificationsSlice } from '../notifications/notifications-slice.js'

const mimeLoader = import('./mime-types.js')

interface UploadResult {
  filename: string
  type: EntityType
}

export const uploadBlob = createAsyncThunk<
  UploadResult,
  { name: string; blob: Blob; defaultType?: string; metadata?: Record<string, string> },
  { state: RootState; dispatch: TunaThunkDispatch }
>(
  'storage/uploadBlob',
  async ({ name, blob, defaultType = 'audio/wav', metadata }, { rejectWithValue, getState, dispatch }) => {
    const userId = getState().user.userId

    invariant(userId, 'User must be logged in to upload a file')

    let accepted = false
    let type: EntityType = blob.type as EntityType
    try {
      for (const [entityType, mimeType] of Object.entries(getState().storage.entityTypeToMimeType)) {
        const matcher = new MimeMatcher(mimeType)
        accepted = matcher.match(blob.type) || entityType === blob.type
        if (accepted) {
          type = entityType as EntityType
          break
        }
      }
    } catch (e) {
      logger.error(`Error checking mime type: ${e}`)
    }

    invariant(accepted, `upload of ${blob.type} is not supported`)

    const filename = createObjectPath({ name, mimeType: blob.type, userId })

    /** Upload the actual file */
    const mime = await mimeLoader
    const contentType = (blob.type ? blob.type : mime.lookup(name)) || defaultType
    const data = await blob.arrayBuffer()

    /**
     * SHA256 signature required
     * @see https://github.com/brix/crypto-js/issues/91
     * @see https://github.com/brix/crypto-js
     * @see https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/private-content-restricting-access-to-s3.html#private-content-origin-access-identity-signature-version-4
     */

    // await API.upload(token, filename, data, contentType)

    /** Get the upload URL */
    const {
      data: signedUrl,
      isError,
      error,
    } = await dispatch(storageApi.endpoints.getUploadUrl.initiate({ filename, contentType, metadata }))

    if (isError || !signedUrl) {
      console.log("Couldn't get signed URL", error)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const message = (error as any).data?.message ?? 'Unable to authorize upload of file'
      dispatch(
        notificationsSlice.actions.setAlert({
          severity: 'error',
          title: 'Upload failed',
          message: `Unable to authorize upload of file: ${message ? message : 'Unknown error'}`,
        })
      )
      return rejectWithValue('Unable to authorize upload of file')
    }

    /** Upload the actual file. If the upload fails, the promise should be rejected */
    await dispatch(storageApi.endpoints.uploadFile.initiate({ signedUrl: signedUrl.url, data, contentType }))

    return { filename, type }
  }
)

export const uploadStoredEntity = createAsyncThunk<
  Persisted<Entity>,
  {
    id?: string
    name: string
    parentId: string | null
    blob: Blob
    message?: string
    isPrivate?: boolean
    tags?: string[]
  },
  { state: RootState; dispatch: TunaThunkDispatch }
>(
  'storage/uploadStoredEntity',
  async (
    { id, name, parentId, blob, message = 'Uploading media...', isPrivate = false, tags },
    { getState, dispatch }
  ) => {
    const userId = getState().user.userId
    if (!userId) {
      throw new Error(`Upload requires an authenticated user`)
    }
    if (!blob.type) {
      throw new Error("Blob must have a valid 'type' property")
    }

    dispatch(notificationsSlice.actions.setAlert({ severity: 'info', message }))

    /** We create the id client side, because we need to tag the blob with it */
    const entityId = id ?? shortUuid()
    const metadata = { entityId }

    const { filename, type } = await dispatch(uploadBlob({ name, blob, metadata })).unwrap()
    const storedObject: StoredObject<Entity> = {
      id,
      name,
      type,
      parentId: parentId ? parentId : undefined,
      tags,
      storage: {
        type: 'S3',
        filename,
      },
    }
    const parent = parentId
      ? await dispatch(entitiesApi.endpoints.loadEntity.initiate({ id: parentId })).unwrap()
      : null

    const entity = { ...storedObject, parentId: parentId ?? undefined }
    const result = await dispatch(entitiesApi.endpoints.createEntity.initiate({ entity, parent, isPrivate })).unwrap()
    /** Now the S3 object is available */
    if (isAudio(result)) {
      await dispatch(mediaApi.endpoints.analyzeAudio.initiate(result))
    }
    return result
  }
)
