import { isHotkey } from '@tunasong/models'
import { useEffect } from 'react'

export type HotkeyHandler = (ev?: KeyboardEvent) => void

/** Register Hotkeys and handlers for the hotkeys. The listeners are registered on the document. To disable, set the second argument */
export const useHotkey = ({
  hotkey,
  handler,
  disabled = false,
  el,
}: {
  hotkey: string
  handler?: HotkeyHandler
  disabled?: boolean
  el?: HTMLElement | null
}) => {
  const element = el ? el : document.body
  useEffect(() => {
    if (disabled) {
      return
    }
    const handleKey = (ev: KeyboardEvent) => {
      if (isHotkey(hotkey)(ev) && handler) {
        ev.preventDefault()
        handler(ev)
      }
    }
    element.addEventListener('keydown', handleKey)
    return () => {
      element.removeEventListener('keydown', handleKey)
    }
  }, [disabled, handler, hotkey, element])
}
