import { type RootState } from '@tunasong/redux'
import type { Persisted } from '@tunasong/schemas'
import { type Entity } from '@tunasong/schemas'
import { useMemo } from 'react'
import { useStore } from 'react-redux'
import type { LayoutSpec, TunaPlugin } from '../plugin-types.js'
import { usePlugins } from './editor-plugins.js'

export type ExtendedLayoutSpec<T extends Entity> = LayoutSpec<T> & { plugin: TunaPlugin }

export const useLayoutsExtended = <T extends Entity>(entity?: Persisted<T> | null): ExtendedLayoutSpec<T>[] => {
  const plugins = usePlugins('all')
  const { getState } = useStore<RootState>()
  const plugin = plugins?.find(p => p.node?.type === entity?.type && p.layouts)

  const layouts = useMemo(() => {
    const pluginLayouts = plugin?.layouts ?? []
    /** Filter out views that have show() === false  */
    return !entity
      ? []
      : pluginLayouts
          .map(({ show, name, ...restProps }) => {
            /** Filter out views that should not be shown in this context  */
            if (show && !show(entity, getState())) {
              return null
            }
            /** Filter out public views */
            if (name.startsWith('public:')) {
              return null
            }

            return { name, plugin, ...restProps }
          })
          .filter(Boolean)
  }, [entity, getState, plugin])

  return layouts as unknown as ExtendedLayoutSpec<T>[]
}

export const useLayout = <T extends Persisted<Entity>>({
  entity,
  layoutName,
}: {
  entity?: T
  layoutName?: string
}): LayoutSpec<T> | null => {
  const viewsExtended = useLayoutsExtended(entity)
  const view = viewsExtended.find(view => view.name === layoutName) ?? viewsExtended[0]
  return view ? view : null
}

export const useLayoutNames = (element?: Persisted<Entity>) => {
  const layoutsExtended = useLayoutsExtended(element)
  const views = useMemo(() => layoutsExtended.map(view => view.name), [layoutsExtended])

  return views
}
