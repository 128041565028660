import classNames from 'classnames'
import useStyles from './icon.styles.js'
import React from 'react'

function Evernote(props: { className?: string }) {
  const { className, ...restProps } = props
  const { classes } = useStyles()
  // color #00A82D
  return (
    <svg
      className={classNames(classes.root, className)}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      //   xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 105.02 122.88"
      {...restProps}
      //   style="enable-background:new 0 0 105.02 122.88"
      //   xml:space="preserve"
    >
      <style type="text/css"></style>
      <g>
        <path d="M32.91,27.61c0,1.22-0.1,3.26-1.33,4.58c-1.32,1.22-3.36,1.32-4.58,1.32H13.96c-3.77,0-6.01,0-7.54,0.2 c-0.81,0.1-1.83,0.51-2.34,0.71c-0.2,0.1-0.2,0-0.1-0.1L33.72,4.08c0.1-0.1,0.2-0.1,0.1,0.1c-0.2,0.51-0.61,1.53-0.71,2.34 c-0.2,1.53-0.2,3.77-0.2,7.54V27.61L32.91,27.61z M60.62,119.1c-3.46-2.24-5.3-5.2-6.01-7.03c-0.71-1.73-1.12-3.67-1.12-5.6 c0-8.46,6.93-15.38,15.49-15.38c2.55,0,4.58,2.04,4.58,4.58c0,1.73-0.92,3.16-2.34,3.97c-0.51,0.31-1.22,0.51-1.73,0.61 c-0.51,0.1-2.44,0.31-3.36,1.12c-1.02,0.82-1.83,2.14-1.83,3.57c0,1.53,0.61,2.96,1.63,3.97c1.83,1.83,4.28,2.85,6.93,2.85 c6.93,0,12.53-5.6,12.53-12.53c0-6.21-4.18-11.72-9.68-14.16c-0.81-0.41-2.14-0.71-3.36-1.02c-1.53-0.31-2.95-0.51-3.06-0.51 C65,83.03,54.3,79.67,53.59,70.2c0,0-3.16,14.26-9.48,18.13c-0.61,0.31-1.43,0.61-2.34,0.82s-1.94,0.3-2.24,0.3 C29.24,90.06,18.34,86.8,10.8,79.06c0,0-5.09-4.18-7.74-15.89c-0.61-2.85-1.83-7.95-2.55-12.74C0.2,48.7,0.1,47.38,0,46.15 c0-4.99,3.06-8.35,6.93-8.86c0.1,0,0.41,0,0.61,0c2.34,0,20.17,0,20.17,0c3.57,0,5.6-0.92,6.93-2.14c1.73-1.63,2.14-3.97,2.14-6.72 c0,0,0-18.54,0-20.89c0-0.1,0-0.51,0-0.61C37.29,3.16,40.65,0,45.64,0c0,0,1.53,0,2.45,0c1.02,0,2.24,0.1,3.36,0.2 c0.82,0.1,1.53,0.31,2.75,0.61c6.21,1.53,7.54,7.84,7.54,7.84s11.72,2.04,17.63,3.06c5.6,1.02,19.46,1.94,22.11,15.89 c6.21,33.21,2.45,65.41,2.14,65.41c-4.38,31.38-30.46,29.85-30.46,29.85C67.54,122.87,63.37,121.03,60.62,119.1L60.62,119.1z M84.05,52.37c-3.36-0.31-6.21,1.02-7.23,3.57c-0.2,0.51-0.41,1.12-0.31,1.43c0.1,0.31,0.31,0.41,0.51,0.51 c1.22,0.61,3.26,0.92,6.21,1.22c2.95,0.31,4.99,0.51,6.32,0.31c0.2,0,0.41-0.1,0.61-0.41s0.1-0.92,0.1-1.43 C89.86,54.71,87.41,52.77,84.05,52.37L84.05,52.37z" />
      </g>
    </svg>
  )
}

export default Evernote
