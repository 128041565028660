import { logger, shortUuid } from '@tunasong/models'
import { isOnline } from '@tunasong/models'
import { features, updateEntitiesStore, type TunaGetState, type TunaThunkDispatch } from '@tunasong/redux'
import type { Entity, EntityType, Persisted } from '@tunasong/schemas'
import { openDatabase } from '@tunasong/storage'
import invariant from 'tiny-invariant'
import type { BlobUpload } from './upload-types.js'
import { blobUploadToTemporaryEntity } from './upload-util.js'

/** To guard against costly bugs that upload data ad-infinitum we set a limit on the number of files to upload per session to 1000. */
const MAX_UPLOADS_PER_SESSION = 100
// We limit the number of upload attempts to 50
const MAX_UPLOAD_ATTEMPTS = 10

// Delay between attempts (10 seconds)
const UPLOAD_RETRY_DELAY = 10000

/** Upload manager that uploads to the server asynchronously from IndexedDB */
export class UploadManager {
  #dispatch: TunaThunkDispatch
  #getState: TunaGetState
  #database: ReturnType<typeof openDatabase<BlobUpload>> | null
  #uploadCount = 0
  #isInUploadLoop = false
  #uploadScheduled = false
  #pendingPromises = new Map<IDBValidKey, ((entity: Persisted<Entity>) => void) | undefined>()

  constructor({
    dbName,
    dispatch,
    getState,
    pushInterval = 1000,
  }: {
    dbName: string
    dispatch: TunaThunkDispatch
    getState: TunaGetState
    pushInterval?: number
  }) {
    this.#dispatch = dispatch
    this.#getState = getState
    this.#database = openDatabase<BlobUpload>(dbName)

    // Run upload every pushInterval milliseconds.
    setInterval(this.upload, pushInterval)
  }

  get isUploading() {
    return this.#isInUploadLoop || this.#uploadScheduled
  }

  uploadNewStoredEntity = async (
    name: string,
    parentId: string | null,
    blob: Blob,
    isPrivate = false,
    tags?: string[]
  ) => {
    invariant(this.#database, `No upload database`)

    if (blob.size <= 0) {
      this.#dispatch(
        features.notifications.actions.setAlert({
          severity: 'warning',
          message: `Cannot upload an empty file: ${name}`,
        })
      )
      return
    }
    if (!blob.type) {
      this.#dispatch(
        features.notifications.actions.setAlert({
          severity: 'warning',
          message: `Cannot upload a file without a mime type: ${name}`,
        })
      )
      return
    }

    if (this.#uploadCount >= MAX_UPLOADS_PER_SESSION) {
      this.#dispatch(
        features.notifications.actions.setAlert({
          severity: 'warning',
          message: `Cannot upload more than ${MAX_UPLOADS_PER_SESSION} files per session`,
        })
      )
      return
    }

    /** Store in the pending uploads database and schedule it for upload */
    const id = shortUuid()
    const type = blob.type as EntityType
    const blobUpload: BlobUpload = { id, name, type, parentId, blob, isPrivate, attempts: 0, tags }
    await this.#database.set(id, blobUpload)

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const userId = this.#getState().user.userId!

    /** Update the entities store optimisticly  */
    try {
      /** @todo blobUploadToTemporaryEntity must be refactored and the mimetype hack removed, and does not work for new entities */
      updateEntitiesStore({
        entities: [blobUploadToTemporaryEntity({ blobUpload, userId })],
        dispatch: this.#dispatch,
        state: this.#getState(),
        updateParent: true,
      })
    } catch (e) {
      logger.warn('Unable to use temporary entity', e)
    }

    const promise = new Promise<Persisted<Entity>>(resolve => {
      this.#pendingPromises.set(id, resolve)
    })

    this.#uploadCount += 1
    this.#uploadScheduled = true

    // trigger an upload async
    this.upload().catch(e => {
      logger.error('Error uploading', e)
    })

    return promise
  }

  getPendingUploadBlobURL = async (id: string) => {
    const item = await this.#database?.get(id)
    if (!item) {
      return
    }
    return URL.createObjectURL(item.blob)
  }

  // the private upload loop

  private upload = async () => {
    if (!this.#database || this.#isInUploadLoop || !isOnline()) {
      return
    }

    this.#isInUploadLoop = true

    for (const key of await this.#database.keys()) {
      const val = await this.#database.get(key)
      if (!val) {
        continue
      }

      const { id, name, parentId, blob, isPrivate, tags, lastAttempt } = val

      // Wait at least UPLOAD_RETRY_DELAY before retrying
      if (lastAttempt && Date.now() - lastAttempt.getTime() < UPLOAD_RETRY_DELAY) {
        continue
      }

      try {
        /** We need to remove the key before uploading to avoid a race */
        await this.#database.delete(key)

        const entity = await this.#dispatch(
          features.storage.thunks.uploadStoredEntity({
            id,
            name,
            parentId,
            blob,
            message: `Uploading ${name}...`,
            tags,
            isPrivate,
          })
        ).unwrap()

        const resolve = this.#pendingPromises.get(key)
        if (!resolve) {
          continue
        }
        resolve(entity)
      } catch (e) {
        /** Re-queue the entity on error */
        logger.warn(`Error uploading ${name}. Rescheduling upload.`)
        const attempts = val.attempts + 1
        if (attempts > MAX_UPLOAD_ATTEMPTS) {
          logger.error(`Failed to upload ${name} after ${MAX_UPLOAD_ATTEMPTS} attempts. Giving up.`)
          continue
        }
        this.#database.set(key, {
          ...val,
          lastAttempt: new Date(),
          attempts,
        })
      } finally {
        /** We have a pending promise we need to handle here */
        this.#pendingPromises.delete(key)
      }
    }
    this.#isInUploadLoop = false
    this.#uploadScheduled = false
  }
}
