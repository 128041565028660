import { Page } from '@tunasong/icons'
import { useElementTypeMedia } from '@tunasong/plugin-lib'
import { type Entity } from '@tunasong/schemas'
import { type FC } from 'react'
import { useMainColors } from '../color/color-hooks.js'

interface EntityIconProps {
  entity: Pick<Entity, 'type'>
  size?: 'small' | 'medium' | 'large'
  DefaultIcon?: FC<{ className: string }>
}

export const EntityIcon: FC<EntityIconProps> = props => {
  const { entity, size = 'medium', DefaultIcon = Page } = props
  const { classes: colorClasses } = useMainColors()
  const { icon: Icon, materialColor: color } = useElementTypeMedia(entity.type)

  const cardColorClass = colorClasses[color]

  return entity ? (
    <Icon
      sx={{
        fontSize: size === 'small' ? 16 : size === 'medium' ? 24 : 32,
      }}
      className={cardColorClass}
    />
  ) : (
    <DefaultIcon className={cardColorClass} />
  )
}
