import { type Entity, type EntityOrElement, type Persisted } from '@tunasong/schemas'
import { type TunaPlugin } from '../plugin-types.js'

export interface RenderEntityProps<T extends Entity = Persisted<Entity>> {
  entity: T
  plugins: TunaPlugin<EntityOrElement>[]
  contentView?: string
  useDefault?: boolean
}

export interface RenderContentProps<T extends EntityOrElement> {
  element: T
  plugins: TunaPlugin<EntityOrElement>[]
  contentView?: string
  useDefault?: boolean
}

export function renderContent<T extends EntityOrElement>({
  element,
  plugins,
  contentView,
  useDefault = true,
}: RenderContentProps<T>) {
  return plugins
    .map(p => {
      const Content = p.components?.Content
      if (element.type !== p.node?.type || !Content) {
        return null
      }
      const key = `plugin-${p.key}-${element.id}`

      if (typeof Content === 'function') {
        return <Content key={key} element={element} plugins={plugins} />
      }
      const contentComponents = Content
      const contentViewNames = Object.keys(contentComponents)
      const defaultContentView = contentViewNames[0]
      if (!defaultContentView) {
        // plugin has no views
        return null
      }

      // If specified view is not found, and we're not using the default, return null
      if (contentView && !contentViewNames.includes(contentView) && !useDefault) {
        return null
      }

      const contentViewWithDefault = contentView ?? element.options?.activeView ?? 'default'

      const activeContentView = contentViewNames.includes(contentViewWithDefault)
        ? contentViewWithDefault
        : defaultContentView

      const RenderContent = contentComponents[activeContentView]

      /** @note we only render for the same type currently */
      return <RenderContent key={key} element={element} plugins={plugins} />
    })
    .filter(Boolean)
}
