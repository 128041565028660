import { getEditorCommands, useEditor, useGlobalAppContext, type Command } from '@tunasong/plugin-lib'
import type { CoreElement } from '@tunasong/schemas'
import { useMemo } from 'react'
import { type NodeEntry } from 'slate'
import { useUserConfig } from './config-service.hook.js'

export const useEditorCommands = ({
  nodeEntry,
  text = '',
}: {
  text?: string
  nodeEntry?: NodeEntry<CoreElement>
}): Command[] => {
  const [maturity] = useUserConfig('featureMaturity')
  // Generate command specs from the custom commands
  const globalContext = useGlobalAppContext()
  const editor = useEditor({ allowNull: true })

  const plugins = editor?.pluginList
  const commands = useMemo(
    () => (editor && plugins ? getEditorCommands({ nodeEntry, plugins, text, globalContext, editor, maturity }) : []),
    [editor, plugins, globalContext, maturity, nodeEntry, text]
  )

  return commands
}
