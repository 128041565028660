import { type ElementPlugin, type TunaPlugin } from '../plugin-types.js'

export function isPlugin(plugin: unknown): plugin is TunaPlugin {
  return typeof plugin === 'object' && plugin !== null && 'key' in plugin
}

export function isElementPlugin(plugin: unknown): plugin is ElementPlugin {
  /** Exclude line from ElementPlugin */
  return Boolean(isPlugin(plugin) && plugin?.node?.type && plugin?.node?.type !== 'line' && plugin?.node?.isElement)
}

/** For plugins that can insert elements interactively, i.e., from the Insert Menu */
export function isInsertElementPlugin(plugin: unknown): plugin is ElementPlugin {
  return Boolean(isPlugin(plugin) && plugin.insertBlock)
}

// export const loadPlugin = async <
//   TElement extends EntityOrElement = CoreElement,
//   TOptions extends PluginOptions = PluginOptions
// >(
//   /** use import('path/to/plugin') here */
//   plugin: LazyCreateTunaPlugin<TElement>,
//   config?: TOptions
// ) => plugin.then(({ default: createPlugin }) => createPlugin(config)) as LazyTunaPlugin
