import Bass from './bass.js'
import DeadFish from './dead-fish.js'
import GuitarTab from './guitar-tab.js'
import Chord from './guitar.js'
import Instagram from './instagram.js'
import Midi from './midi.js'
import Pdf from './pdf.js'
import Effect from './pedal.js'
import Piano from './piano.js'
import Spotify from './spotify.js'
import YouTube from './youtube.js'
import Evernote from './evernote.js'

import TableRows from '@mui/icons-material/TableRowsOutlined'
import AccessDenied from '@mui/icons-material/NotInterested'
import AccountTree from '@mui/icons-material/AccountTreeOutlined'
import Activities from '@mui/icons-material/ViewListOutlined'
import Add from '@mui/icons-material/AddOutlined'
import Analyze from '@mui/icons-material/AssessmentOutlined'
import ArrowDropDown from '@mui/icons-material/ArrowDropDownOutlined'
import ArrowRight from '@mui/icons-material/ArrowRightOutlined'
import Audio from '@mui/icons-material/GraphicEqOutlined'
import Band from '@mui/icons-material/GroupsOutlined'
import Block from '@mui/icons-material/Crop169Outlined'
import Bold from '@mui/icons-material/FormatBoldOutlined'
import Brain from '@mui/icons-material/PsychologyOutlined'
import Calendar from '@mui/icons-material/CalendarMonth'
import CalendarEvent from '@mui/icons-material/CalendarToday'
import CameraFront from '@mui/icons-material/CameraFrontOutlined'
import CameraRear from '@mui/icons-material/CameraRearOutlined'
import Cancel from '@mui/icons-material/CancelOutlined'
import Chart from '@mui/icons-material/BarChartOutlined'
import ChartData from '@mui/icons-material/AddchartOutlined'
import Chat from '@mui/icons-material/ChatBubbleOutline'
import Check from '@mui/icons-material/CheckOutlined'
import Close from '@mui/icons-material/CloseOutlined'
import Collaboration from '@mui/icons-material/GroupWorkOutlined'
import Columns from '@mui/icons-material/ViewColumnOutlined'
import Launch from '@mui/icons-material/LaunchOutlined'
import Compose from '@mui/icons-material/RecordVoiceOverOutlined'
import Context from '@mui/icons-material/CenterFocusStrongOutlined'
import Controls from '@mui/icons-material/TuneOutlined'
import Copy from '@mui/icons-material/FileCopyOutlined'
import Daw from '@mui/icons-material/ArtTrackOutlined'
import Debug from '@mui/icons-material/AdbOutlined'
import Delete from '@mui/icons-material/DeleteOutlined'
import Divider from '@mui/icons-material/HorizontalSplitOutlined'
import Download from '@mui/icons-material/DownloadOutlined'
import DragHandle from '@mui/icons-material/DragHandleOutlined'
import DragIndicator from '@mui/icons-material/DragIndicatorOutlined'
import Drawing from '@mui/icons-material/GestureOutlined'
import Edit from '@mui/icons-material/EditOutlined'
import Eject from '@mui/icons-material/EjectOutlined'
import Embed from '@mui/icons-material/OpenInBrowserOutlined'
import Emoji from '@mui/icons-material/EmojiEmotionsOutlined'
import ErrorOutline from '@mui/icons-material/ErrorOutlineOutlined'
import Exit from '@mui/icons-material/ExitToAppOutlined'
import ExpandLess from '@mui/icons-material/ExpandLessOutlined'
import ExpandMore from '@mui/icons-material/ExpandMoreOutlined'
import Fail from '@mui/icons-material/DoDisturbOutlined'
import Favorite from '@mui/icons-material/FavoriteOutlined'
import Folder from '@mui/icons-material/FolderOutlined'
import FolderOpen from '@mui/icons-material/FolderOpenOutlined'
import Form from '@mui/icons-material/TextFormatOutlined'
import FormatAlignCenter from '@mui/icons-material/FormatAlignCenterOutlined'
import FormatAlignJustify from '@mui/icons-material/FormatAlignJustifyOutlined'
import FormatAlignLeft from '@mui/icons-material/FormatAlignLeftOutlined'
import FormatAlignRight from '@mui/icons-material/FormatAlignRightOutlined'
import FullScreen from '@mui/icons-material/FullscreenOutlined'
import FullScreenExit from '@mui/icons-material/FullscreenExitOutlined'
import Functions from '@mui/icons-material/FunctionsOutlined'
import Gallery from '@mui/icons-material/CollectionsOutlined'
import Game from '@mui/icons-material/GamesOutlined'
import GridView from '@mui/icons-material/GridViewOutlined'
import Heading from '@mui/icons-material/TitleOutlined'
import Headset from '@mui/icons-material/HeadsetOutlined'
import Help from '@mui/icons-material/HelpOutline'
import Home from '@mui/icons-material/HomeOutlined'
import Image from '@mui/icons-material/ImageOutlined'
import Info from '@mui/icons-material/InfoOutlined'
import Italic from '@mui/icons-material/FormatItalicOutlined'
import Labs from '@mui/icons-material/BuildOutlined'
import Left from '@mui/icons-material/ChevronLeftOutlined'
import LibraryMusic from '@mui/icons-material/LibraryMusicOutlined'
import Link from '@mui/icons-material/LinkOutlined'
import MeetingRoom from '@mui/icons-material/MeetingRoomOutlined'
import Menu from '@mui/icons-material/MenuOutlined'
import MenuOpen from '@mui/icons-material/MenuOpenOutlined'
import MicOff from '@mui/icons-material/MicOffOutlined'
import Microphone from '@mui/icons-material/MicNoneOutlined'
import MoreVertIcon from '@mui/icons-material/MoreVertOutlined'
import Move from '@mui/icons-material/MoveUpOutlined'
import MuscialScore from '@mui/icons-material/LibraryMusicOutlined'
import MusicNote from '@mui/icons-material/MusicNoteOutlined'
import Next from '@mui/icons-material/KeyboardArrowRightOutlined'
import Note from '@mui/icons-material/NoteAltOutlined'
import Offline from '@mui/icons-material/CloudOffOutlined'
import Online from '@mui/icons-material/CloudOutlined'
import OpenLink from '@mui/icons-material/OpenInNewOutlined'
import Page from '@mui/icons-material/SubjectOutlined'
import Pause from '@mui/icons-material/PauseOutlined'
import Performance from '@mui/icons-material/SlideshowOutlined'
import Play from '@mui/icons-material/PlayArrowOutlined'
import PlayList from '@mui/icons-material/QueueMusicOutlined'
import Poll from '@mui/icons-material/PollOutlined'
import PollFinalOption from '@mui/icons-material/HowToRegOutlined'
import PollResults from '@mui/icons-material/BallotOutlined'
import Pool from '@mui/icons-material/AudiotrackOutlined'
import Present from '@mui/icons-material/PresentToAllOutlined'
import PresentOff from '@mui/icons-material/CancelPresentationOutlined'
import Preset from '@mui/icons-material/SettingsOutlined'
import Prev from '@mui/icons-material/KeyboardArrowLeftOutlined'
import Preview from '@mui/icons-material/VisibilityOutlined'
import Print from '@mui/icons-material/PrintOutlined'
import Question from '@mui/icons-material/QuestionMarkOutlined'
import Recent from '@mui/icons-material/RestoreOutlined'
import Record from '@mui/icons-material/FiberManualRecordOutlined'
import RecordOff from '@mui/icons-material/FiberManualRecordOutlined'
import Refresh from '@mui/icons-material/RefreshOutlined'
import Remove from '@mui/icons-material/RemoveOutlined'
import Rename from '@mui/icons-material/DriveFileRenameOutline'
import Resize from '@mui/icons-material/AspectRatioOutlined'
import RestoreFromTrash from '@mui/icons-material/RestoreFromTrashOutlined'
import RestorePage from '@mui/icons-material/RestorePageOutlined'
import Right from '@mui/icons-material/ChevronRightOutlined'
import Room from '@mui/icons-material/VideoCallOutlined'
import RotateLeft from '@mui/icons-material/RotateLeftOutlined'
import RotateRight from '@mui/icons-material/RotateRightOutlined'
import Save from '@mui/icons-material/SaveOutlined'
import Schema from '@mui/icons-material/ListAltOutlined'
import Search from '@mui/icons-material/SearchOutlined'
import Settings from '@mui/icons-material/SettingsOutlined'
import Share from '@mui/icons-material/ShareOutlined'
import Song from '@mui/icons-material/LyricsOutlined'
import Star from '@mui/icons-material/StarOutlined'
import StarOutlined from '@mui/icons-material/StarBorderOutlined'
import Stop from '@mui/icons-material/StopOutlined'
import Strikethrough from '@mui/icons-material/FormatStrikethroughOutlined'
import Suggestion from '@mui/icons-material/LightbulbOutlined'
import SwitchOff from '@mui/icons-material/AlbumOutlined'
import SwitchOn from '@mui/icons-material/AlbumOutlined'
import Tag from '@mui/icons-material/TagOutlined'
import Todo from '@mui/icons-material/CheckBoxOutlined'
import Transform from '@mui/icons-material/TransformOutlined'
import Transpose from '@mui/icons-material/PlusOneOutlined'
import Tuner from '@mui/icons-material/VerticalAlignCenterOutlined'
import Underlined from '@mui/icons-material/FormatUnderlinedOutlined'
import Upload from '@mui/icons-material/UploadOutlined'
import VideoCam from '@mui/icons-material/VideocamOutlined'
import VideoCamOff from '@mui/icons-material/VideocamOffOutlined'
import View from '@mui/icons-material/VisibilityOutlined'
import ViewSidebar from '@mui/icons-material/ViewSidebarOutlined'
import WebAsset from '@mui/icons-material/WebAssetOutlined'
import type { FC } from 'react'
import type { SvgIconProps, SxProps } from '@mui/material'

const Video = Room
const Pedal = Effect
const PedalIcon = Effect
const Guitar = Chord
const ViewList = Activities
const Section = MuscialScore
const Mixer = Audio
const Comment = Chat
const Convert = Transform

export {
  AccessDenied,
  AccountTree,
  Activities,
  Add,
  Analyze,
  ArrowDropDown,
  ArrowRight,
  Audio,
  Band,
  Bass,
  Block,
  Bold,
  Brain,
  Calendar,
  CalendarEvent,
  CameraFront,
  CameraRear,
  Cancel,
  Chart,
  ChartData,
  Chat,
  Check,
  Chord,
  Close,
  Collaboration,
  Columns,
  Comment,
  Compose,
  Context,
  Controls,
  Convert,
  Copy,
  Daw,
  DeadFish,
  Debug,
  Delete,
  Divider,
  Download,
  DragHandle,
  DragIndicator,
  Drawing,
  Edit,
  Effect,
  Eject,
  Embed,
  Emoji,
  ErrorOutline,
  Exit,
  ExpandLess,
  ExpandMore,
  Fail,
  Favorite,
  Folder,
  FolderOpen,
  Form,
  FormatAlignCenter,
  FormatAlignJustify,
  FormatAlignLeft,
  FormatAlignRight,
  FullScreen,
  FullScreenExit,
  Functions,
  Gallery,
  Game,
  GridView,
  Guitar,
  GuitarTab,
  Heading,
  Headset,
  TableRows,
  Help,
  Home,
  Image,
  Info,
  Instagram,
  Italic,
  Labs,
  Left,
  LibraryMusic,
  Link,
  MeetingRoom,
  Menu,
  MenuOpen,
  MicOff,
  Microphone,
  Midi,
  Mixer,
  MoreVertIcon,
  Move,
  MuscialScore,
  MusicNote,
  Next,
  Note,
  Offline,
  Online,
  OpenLink,
  Page,
  Pause,
  Pdf,
  Pedal,
  PedalIcon,
  Performance,
  Piano,
  Play,
  PlayList,
  Poll,
  PollFinalOption,
  PollResults,
  Pool,
  Present,
  PresentOff,
  Preset,
  Prev,
  Preview,
  Print,
  Question,
  Recent,
  Record,
  RecordOff,
  Refresh,
  Remove,
  Rename,
  Resize,
  RestoreFromTrash,
  RestorePage,
  Right,
  Room,
  RotateLeft,
  RotateRight,
  Save,
  Schema,
  Search,
  Section,
  Settings,
  Share,
  Song,
  Spotify,
  Star,
  StarOutlined,
  Stop,
  Strikethrough,
  Suggestion,
  SwitchOff,
  SwitchOn,
  Tag,
  Todo,
  Transform,
  Transpose,
  Tuner,
  Underlined,
  Upload,
  Video,
  VideoCam,
  VideoCamOff,
  View,
  ViewList,
  ViewSidebar,
  WebAsset,
  YouTube,
  Evernote,
  Launch,
}

// Use one of the icons as a type template
interface IconProps extends SvgIconProps {
  sx?: SxProps
  className?: string
}

type SvgIconComponent = FC<IconProps>
export type { SvgIconComponent, IconProps }
