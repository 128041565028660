import { Button, ButtonGroup, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { Resize } from '@tunasong/icons'
import type { EmbedSize, Sizable } from '@tunasong/models'
import { EditorQueries, useEditor, type RenderMenuItemProps } from '@tunasong/plugin-lib'
import { isCoreElement, type Entity } from '@tunasong/schemas'
import { useCallback, type FC } from 'react'
import invariant from 'tiny-invariant'

export const SizerAction: FC<RenderMenuItemProps> = props => {
  const { element, onClose } = props
  const editor = useEditor({ allowNull: true })

  const handleSize = useCallback(
    (size: EmbedSize) => () => {
      invariant(editor && isCoreElement(element), `Editor is not set or element is not a CoreElement`)
      EditorQueries.updateElement(editor, element, {
        size,
      })
    },
    [editor, element]
  )

  if (!editor) {
    return null
  }
  const { size = 'medium' } = element as Sizable<Entity>

  return (
    <ListItem onClick={onClose}>
      <ListItemIcon>{<Resize />}</ListItemIcon>
      <ListItemText>
        <ButtonGroup size="small">
          <Button disabled={size === 'small'} onClick={handleSize('small')}>
            Small
          </Button>
          <Button disabled={size === 'medium'} onClick={handleSize('medium')}>
            Medium
          </Button>
          <Button disabled={size === 'large'} onClick={handleSize('large')}>
            Large
          </Button>
        </ButtonGroup>
      </ListItemText>
    </ListItem>
  )
}

export default SizerAction
