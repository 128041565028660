/* eslint-disable no-underscore-dangle */
/**
 * Adapted from https://github.com/yjs/y-indexeddb
 */

import * as Y from 'yjs'
import * as idb from 'lib0/indexeddb'
import * as promise from 'lib0/promise'
import { Observable } from 'lib0/observable'
import invariant from 'tiny-invariant'
import { logger } from '@tunasong/models'

const customStoreName = 'custom'
const updatesStoreName = 'updates'

export const PREFERRED_TRIM_SIZE = 500

/**
 * @param {IndexeddbPersistence} idbPersistence
 * @param {function(IDBObjectStore):void} [beforeApplyUpdatesCallback]
 * @param {function(IDBObjectStore):void} [afterApplyUpdatesCallback]
 */
export const fetchUpdates = async (
  idbPersistence: IndexeddbPersistence,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  beforeApplyUpdatesCallback: (store: IDBObjectStore) => void = (store: IDBObjectStore) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  afterApplyUpdatesCallback: (store: IDBObjectStore) => void = (store: IDBObjectStore) => {}
) => {
  invariant(idbPersistence.db !== null, 'IndexeddbPersistence.db is null')
  const [updatesStore] = idb.transact(/** @type {IDBDatabase} */ idbPersistence.db, [updatesStoreName]) // , 'readonly')
  return idb
    .getAll(updatesStore, idb.createIDBKeyRangeLowerBound(idbPersistence._dbref, false))
    .then(updates => {
      if (!idbPersistence._destroyed) {
        beforeApplyUpdatesCallback(updatesStore)

        Y.transact(
          idbPersistence.doc,
          () => {
            logger.debug(`Applying batch of ${updates.length} updates (transaction) from IndexedDB`)
            for (const val of updates) {
              Y.applyUpdate(idbPersistence.doc, val)
            }
          },
          idbPersistence,
          false
        )
        afterApplyUpdatesCallback(updatesStore)
      }
    })
    .then(() =>
      idb.getLastKey(updatesStore).then(lastKey => {
        idbPersistence._dbref = lastKey + 1
      })
    )
    .then(() =>
      idb.count(updatesStore).then(cnt => {
        idbPersistence._dbsize = cnt
      })
    )
    .then(() => updatesStore)
}

/**
 * @param {IndexeddbPersistence} idbPersistence
 * @param {boolean} forceStore
 */
export const storeState = (idbPersistence: IndexeddbPersistence, forceStore: boolean = true) =>
  fetchUpdates(idbPersistence).then(updatesStore => {
    if (forceStore || idbPersistence._dbsize >= PREFERRED_TRIM_SIZE) {
      idb
        // From Typescript 5.7 we get a type error using UInt8Array directly due to limitations in the idb types
        // @ts-ignore
        .addAutoKey(updatesStore, Y.encodeStateAsUpdate(idbPersistence.doc))
        .then(() => idb.del(updatesStore, idb.createIDBKeyRangeUpperBound(idbPersistence._dbref, true)))
        .then(() =>
          idb.count(updatesStore).then(cnt => {
            idbPersistence._dbsize = cnt
          })
        )
    }
  })

export const clearDocument = (name: string) => idb.deleteDB(name)

export class IndexeddbPersistence extends Observable<string> {
  _db: Promise<IDBDatabase>
  name: string
  _dbref: number
  _dbsize: number
  _destroyed: boolean
  _storeTimeout: number
  _storeTimeoutId: NodeJS.Timeout | null
  doc: Y.Doc
  synced: boolean
  db: IDBDatabase | null
  whenSynced: Promise<IndexeddbPersistence>

  constructor({ cacheName, doc }: { cacheName: string; doc: Y.Doc }) {
    super()
    this.doc = doc

    this.name = cacheName
    this._dbref = 0
    this._dbsize = 0
    this._destroyed = false
    this.db = null
    this.synced = false
    this._db = idb.openDB(cacheName, db => idb.createStores(db, [['updates', { autoIncrement: true }], ['custom']]))
    this.whenSynced = promise.create(resolve => this.on('synced', () => resolve(this)))

    this._db.then(db => {
      this.db = db
    })
    /**
     * Timeout in ms untill data is merged and persisted in idb.
     */
    this._storeTimeout = 1000
    this._storeTimeoutId = null

    this._storeUpdate = this._storeUpdate.bind(this)
    doc.on('update', this._storeUpdate)

    this.destroy = this.destroy.bind(this)
    doc.on('destroy', this.destroy)
  }

  sync = async () => {
    if (this.synced) {
      return
    }
    const beforeApplyUpdatesCallback = () => {
      // 2024-12-01 Removed this to avoid storing the whole state in the updates store for every load
      // return idb.addAutoKey(updatesStore, Y.encodeStateAsUpdate(this.doc))
    }
    const afterApplyUpdatesCallback = () => {
      if (this._destroyed) {
        return this
      }
      this.synced = true
      this.emit('synced', [this])
    }
    await this._db
    return fetchUpdates(this, beforeApplyUpdatesCallback, afterApplyUpdatesCallback)
  }

  _storeUpdate(update: Uint8Array, origin: unknown) {
    if (this.db && origin !== this) {
      const [updatesStore] = idb.transact(/** @type {IDBDatabase} */ this.db, [updatesStoreName])
      // From Typescript 5.7 we get a type error using UInt8Array directly due to limitations in the idb types
      // @ts-ignore
      idb.addAutoKey(updatesStore, update)
      if (++this._dbsize >= PREFERRED_TRIM_SIZE) {
        // debounce store call
        if (this._storeTimeoutId !== null) {
          clearTimeout(this._storeTimeoutId)
        }
        this._storeTimeoutId = setTimeout(() => {
          storeState(this, false)
          this._storeTimeoutId = null
        }, this._storeTimeout)
      }
    }
  }

  async destroy() {
    if (this._storeTimeoutId) {
      clearTimeout(this._storeTimeoutId)
    }
    this.doc.off('update', this._storeUpdate)
    this.doc.off('destroy', this.destroy)
    this._destroyed = true
    return this._db.then(db => {
      db.close()
    })
  }

  /**
   * Destroys this instance and removes all data from indexeddb.
   */
  async clearData(): Promise<void> {
    return this.destroy().then(() => {
      idb.deleteDB(this.name)
    })
  }

  async get(key: string): Promise<string | number | ArrayBuffer | Date | unknown> {
    return this._db.then(db => {
      const [custom] = idb.transact(db, [customStoreName], 'readonly')
      return idb.get(custom, key)
    })
  }

  async set(
    key: string,
    value: string | number | boolean | ArrayBuffer | Date
  ): Promise<string | number | ArrayBuffer | Date> {
    return this._db.then(db => {
      const [custom] = idb.transact(db, [customStoreName])
      return idb.put(custom, value, key)
    })
  }

  async del(key: string): Promise<undefined> {
    return this._db.then(db => {
      const [custom] = idb.transact(db, [customStoreName])
      return idb.del(custom, key)
    })
  }
}
