import type { Entity, EntityType, Persisted } from '@tunasong/schemas'
import type { BlobUpload } from './upload-types.js'
import { getEntityTypeFromMime } from '@tunasong/models'

export const blobUploadToTemporaryEntity = ({
  blobUpload,
  userId,
}: {
  blobUpload: BlobUpload
  userId: string
}): Persisted<Entity> => {
  const type = (blobUpload.type as EntityType) ?? getEntityTypeFromMime(blobUpload.blob.type)
  if (!type) {
    throw new Error(`Cannot upload a file with mime type ${blobUpload.blob.type}`)
  }
  return {
    id: blobUpload.id,
    parentId: blobUpload.parentId ?? undefined,
    name: blobUpload.name,
    type,
    storageUrls: {
      // @todo this expires, but we are not using it...
      expiresAtISODateTime: new Date().toISOString(),
      url: URL.createObjectURL(blobUpload.blob),
    },
    userId,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  }
}
