import React, { Fragment } from 'react'
import { type TunaEditor, type TunaPlugin } from '../plugin-types.js'

export const renderContext = <T extends Record<string, unknown>>(
  editor: TunaEditor,
  context: T,
  Component:
    | React.FunctionComponent<{
        title?: string
        subtitle?: string
        context?: T
      }>
    | typeof Fragment = Fragment,
  plugins: TunaPlugin[]
) => (
  <>
    {plugins.map((p, idx) =>
      p.components?.Context ? (
        <Component
          key={idx}
          title={p.description}
          subtitle={p.getContextDescription && p.getContextDescription(context)}
          context={context}
        >
          <p.components.Context editor={editor} context={context} />
        </Component>
      ) : null
    )}
  </>
)
